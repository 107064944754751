<script lang="ts">
  import yaml from "js-yaml";
  import Axios from "axios";
  import { media } from "./store";
  import type { ISectionsData, ILogoData } from "./types";

  let defaultLang = "en";
  let languages = ["en", "de"];
  let menuOpen = false;

  const loadSectionsData = async (): Promise<ISectionsData[]> => {
    try {
      const result = await Axios.get(`/data.yaml`);
      if (result?.data) {
        return yaml.load(result.data).sections;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const loadLogoData = async (): Promise<ILogoData> => {
    try {
      const result = await Axios.get(`/data.yaml`);
      if (result?.data) {
        return yaml.load(result.data).logo;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleClick = () => {
    menuOpen = !menuOpen;
  };
</script>

{#await loadSectionsData()}
  <p>...loading</p>
{:then loadedData}
  <main>
    <nav>
      {#if !$media.mobile}
        <ul class="menu">
          {#each loadedData as section}
            <li class="menu-item">
              <a href={`#${section.id}`}>{section.name[defaultLang]}</a>
            </li>
          {/each}
        </ul>
      {:else}
        <div
          class={`hamburger ${menuOpen ? "open" : "closed"}`}
          on:click={() => handleClick()}
        >
          <span /><span /><span />
        </div>
        {#if menuOpen}
          <div class="menu-mobile">
            <ul class="menu-list-mobile">
              {#each loadedData as section}
                <li class="menu-item-mobile">
                  <a href={`#${section.id}`}>{section.name[defaultLang]}</a>
                </li>
              {/each}
            </ul>
          </div>
        {/if}
      {/if}
      <form class="toggle">
        {#each languages as lang, i}
          <input
            class:selected={defaultLang === languages[i]}
            type="radio"
            bind:group={defaultLang}
            value={lang}
            checked={defaultLang === languages[i]}
          />

          <label for="language">{lang}<span class="hidden">{lang}</span></label>
        {/each}
      </form>
    </nav>
    {#each loadedData as section, j}
      {#if j < loadedData.length - 1}
        <section
          id={section.id}
          style={!$media.mobile &&
            `background-image: url(${section.backgroundImg})`}
        >
          {#await loadLogoData()}
            <div>...loading logo</div>
          {:then loadedLogo}
            <div class="logo">
              <img src={loadedLogo.img} alt="represeta logo" />
              {#if j === 0 && !$media.mobile}
                <span>{loadedLogo.text}</span>
              {/if}
            </div>
          {/await}
          <h2 class="title-container">
            {$media.mobile
              ? section.name[defaultLang]
              : section.title[defaultLang]}
          </h2>
          {#if $media.mobile}
            <img
              src={section.backgroundImg}
              alt="background"
              class="mobile-image"
            />
            <div class="text-content-mobile">
              <h3>{section.title[defaultLang]}</h3>
              {#if section.text[defaultLang]?.length}
                <p class="description">
                  {@html section.text[defaultLang].replace(/\\n/g, "</p><p>")}
                </p>
              {/if}
            </div>
          {/if}
        </section>
        {#if section.text[defaultLang]?.length && !$media.mobile}
          <p class="description">
            {@html section.text[defaultLang].replace(/\\n/g, "</p><p>")}
          </p>
        {/if}
      {:else}
        <section id={section.id} class="contact">
          <h2>{section.name[defaultLang]}</h2>
          <h3>{section.title[defaultLang]}</h3>
          {#if section.text[defaultLang]?.length}
            <p class="description">
              {@html section.text[defaultLang].replace(/\\n/g, "<br />")}
            </p>
          {/if}
        </section>
      {/if}
    {/each}
  </main>
{:catch error}
  <p>something bad happened... Sorry!</p>
{/await}

<style>
  :root {
    --blue: #009ae0;
    --violet: #2d3680;
    --grey: #949c9d;
    --palegrey: #adb4b4;
    --light: #f0f1f0;
  }
  main {
    height: 100%;
    width: auto;
    padding: 50px;
  }
  section {
    margin-bottom: 50px;
    width: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .menu {
    display: flex;
    list-style-type: none;
  }
  .menu-item a {
    text-decoration: none;
    color: var(--grey);
    font-family: "LotaGrotesqueAlt2-Light";
    font-size: 2rem;
  }
  .menu-item:not(:last-of-type):after {
    margin-right: 0.7rem;
    content: "|";
    color: var(--grey);
    margin-left: 0.6rem;
    font-family: "LotaGrotesqueAlt2-Light";
    font-size: 2rem;
  }
  .menu-item a:hover {
    color: var(--blue);
  }
  .logo {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: calc(100px + 2rem);
  }
  .logo img {
    max-width: 230px;
    margin-bottom: 0.5rem;
  }
  .logo span {
    color: #fff;
  }
  .title-container {
    position: absolute;
    top: 40%;
    left: 100px;
    color: #fff;
    font-size: min(max(1rem, 4vw), 4.5rem);
    max-width: 70%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.4);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: "LotaGrotesqueAlt2-Light";
  }

  h3 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
  }
  .mobile-image {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: 40%;
    object-fit: cover;
  }
  .description {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    display: inline-block;
    max-width: 80%;
    padding-left: 100px;
    line-height: 1.4;
  }
  .hidden {
    display: none;
  }
  nav {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  .toggle {
    display: flex;
    height: fit-content;
    margin: auto 0 auto 2rem;
  }
  .toggle input {
    appearance: none;
    border: none;
    position: absolute;
    width: 90px;
    height: 41px;
    color: var(--grey);
  }
  .toggle input:last-of-type {
    transform: translateX(90px);
  }

  .toggle label {
    font-size: 1em;
    text-align: center;
    padding: 0.5em 2em;
    border: 2px solid var(--grey);
    color: var(--grey);
  }

  .toggle label:hover {
    cursor: pointer;
    background-color: var(--grey);
  }

  .toggle input.selected + label {
    background-color: var(--grey);
    color: white;
  }

  .toggle input.selected + label {
    background-color: var(--grey);
    color: white;
  }
  .contact h2,
  .contact h3 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: "LotaGrotesqueAlt2-Light";
    margin-top: 2rem;
  }
  .contact h2 {
    font-size: min(max(1rem, 4vw), 4rem);
  }
  .contact h3 {
    font-size: 2rem;
  }
  .contact p {
    padding-left: 0.3rem;
  }

  .text-content-mobile {
    background-color: var(--light);
    padding: 0 1rem;
  }
  .text-content-mobile h3 {
    padding-top: 1rem;
    font-size: 2rem;
    color: var(--violet);
    line-height: 1.2;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .text-content-mobile p {
    color: var(--grey);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: "LotaGrotesqueAlt2-Light";
  }
  .hamburger {
    height: 18px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .hamburger span {
    border-top: 2px solid #fff;
    height: auto;
    width: 100%;
    transform: rotate(0deg) translateY(0px);
    transition: all 0.2s;
  }
  .hamburger.open span:nth-child(1) {
    display: none;
  }
  .hamburger.open span:nth-child(2) {
    transform: rotate(45deg) translateY(11px);
    border-color: #000;
  }
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg) translateY(-11px);
    border-color: #000;
  }
  .menu-mobile {
    width: 100%;
    display: flex;
    background-color: var(--light);
    padding: 30px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .menu-list-mobile {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
  .menu-item-mobile {
    text-decoration: none;
    color: var(--grey);
    font-family: "LotaGrotesqueAlt2-Light";
    font-size: 2rem;
    line-height: 1.5;
  }

  @media only screen and (max-width: 860px) {
    main {
      height: unset;
      width: auto;
      padding: unset;
      background-color: var(--grey);
    }
    nav {
      padding-top: 1rem;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 1rem;
    }
    section {
      margin-bottom: unset;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      position: static;
    }
    .title-container {
      position: static;
      top: unset;
      left: unset;
      color: var(--violet);
      font-size: 2rem;
      max-width: unset;
      background-color: unset;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-family: "LotaGrotesqueAlt1-Regular";
      padding: 0.2rem 1rem;
    }
    .description {
      font-size: 1rem;
      margin-bottom: 2rem;
      display: inline-block;
      max-width: unset;
      padding-left: unset;
      line-height: 1.2;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-family: "LotaGrotesqueAlt2-Light";
    }
    .toggle {
      margin-top: 2rem;
    }
    .toggle input {
      appearance: none;
      border: none;
      position: absolute;
      width: 50px;
      height: 30px;
      color: #fff;
    }
    .toggle input:last-of-type {
      transform: translateX(50px);
    }

    .toggle label {
      font-size: 1em;
      text-align: center;
      padding: 0.3em 1em;
      border: none;
      color: var(--palegrey);
    }

    .toggle label:hover {
      cursor: pointer;
      background-color: var(--blue);
    }

    .toggle input.selected + label {
      background-color: var(--blue);
      color: white;
    }

    .toggle input.selected + label {
      background-color: var(--blue);
      color: white;
    }
    .logo {
      display: inline-block;
      top: 1rem;
      left: 1rem;
    }
    .logo img {
      max-width: 130px;
      margin-bottom: 0.5rem;
    }
    .contact {
      background-color: #fff;
      padding: 1rem;
      width: unset;
    }
    .contact h2 {
      font-size: 1.5rem;
    }
    .contact h3 {
      font-size: 1rem;
    }
    .contact p {
      padding: 0;
    }
  }
</style>
